export default [
  {
    resource: 'Auth',
    action: 'Read',
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    resource: 'Network',
    action: 'Read',
    title: 'Networks',
    route: 'networks',
    icon: 'GlobeIcon',
  },
  {
    resource: 'Target',
    action: 'Read',
    title: 'Targets',
    route: 'targets',
    icon: 'TargetIcon',
  },
  {
    resource: 'Issue',
    action: 'Read',
    title: 'Issues',
    route: 'issues',
    icon: 'AlertTriangleIcon',
  },
  {
    resource: 'User',
    action: 'Read',
    title: 'Users',
    route: 'users',
    icon: 'UsersIcon',
  },
  {
    resource: 'NotificationGroup',
    action: 'Read',
    title: 'Notification Groups',
    route: 'notification-groups',
    icon: 'UsersIcon',
  },
  {
    resource: 'Organisation',
    action: 'Read',
    title: 'Organisation',
    route: 'organisation-view',
    icon: 'GlobeIcon',
  },
  {
    resource: 'All',
    action: 'Manage',
    title: 'Organisations',
    route: 'organisations',
    icon: 'HardDriveIcon',
  },
  {
    resource: 'Role',
    action: 'Read',
    title: 'Roles',
    route: 'roles',
    icon: 'LockIcon',
  },
  {
    resource: 'Activity',
    action: 'Read',
    title: 'Activities',
    route: 'activities',
    icon: 'ActivityIcon',
  },
]
