<template>
  <layout-vertical>

    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>

    <template #footer="">
      <app-footer />
    </template>

  </layout-vertical>
</template>

<script>
import LayoutVertical from '../layout-vertical/LayoutVertical.vue'
import Navbar from '../components/Navbar.vue'
import AppFooter from '../components/AppFooter.vue'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
    AppFooter,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
